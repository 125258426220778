.layout {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
}

.content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px) !important;
  width: 100% !important;
}

.contentChildren {
  margin: 24px;
  min-height: calc(100% - 48px) !important;
}
