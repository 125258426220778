.container {
  width: 100%;
  height: calc(100% - 60px);
  border-radius: 8px;
}

.scrollContainer {
  width: 100%;
  height: calc(100% - 55px);
  overflow: hidden;
  overflow-y: auto;
}

.listContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  align-items: stretch;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.loadingIcon {
  font-size: 50px;
  color: #1890ff !important;
}

.loadingText {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 48px;
}

.coincidenciasContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
}

.modalBodyContainer {
  margin: -24px;
  padding: 24px;
  max-height: 60vh !important;
  overflow: hidden;
  overflow-y: auto;
}
