.container {
  width: 100%;
  padding-bottom: 24px;
}

.tabContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 16px 16px 16px;
}
