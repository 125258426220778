.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: #eee;
  padding: 10px;
  padding-top: 55px;
  box-sizing: border-box;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.page {
  width: 100%;
  max-width: 1000px;
  background-color: #fff;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  break-before: page;
}

.title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.title .h1,
.title .h3 {
  width: 100%;
  text-align: center;
  margin: 0;
  line-height: 1em;
  font-family: 'Times New Roman', Times, serif;
}

.h1 {
  font-size: 1.5em;
}

.h3 {
  font-size: 0.9em;
  font-weight: lighter;
}

.duaContent {
  font-family: Arial, Helvetica, sans-serif;
}

.duaContent .cell {
  border-bottom: 1px solid black;
  width: 100%;
}

.duaSection {
  font-weight: bold;
  font-size: 11px;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  border-top: none;
  width: 100%;
  height: 100%;
  min-height: 20px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.row:first-child {
  border-top: 1px solid #000;
}

.row .col:last-child {
  border-right: none;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 20px;
  border-right: 1px solid #000;
  box-sizing: border-box;
}

.col .cell:last-child {
  border-bottom: none;
}

.rowDoubleLine {
  min-height: 40px;
}

.cell {
  border-bottom: 1px solid #000;
  padding: 0 5px;
  font-size: 10px;
}

.cellDoubleLine {
  font-size: 8px;
  line-height: 8px;
  height: 20px;
}

.cellDoubleLine .row,
.cellDoubleLine .col {
  min-height: 10px !important;
}

.data {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
}

.posicionArancelaria {
  font-size: 10px;
}

.rectangle {
  display: block;
  height: 10px;
  width: 16px;
  border: 1px solid #000;
  margin-left: 12px;
}

.cellAduanas {
  position: relative;
}

.cellAduanas .data {
  position: absolute;
  top: -8px;
}

.cellAduanas > .row {
  position: absolute;
  bottom: -5px;
}

@media print {
  @page {
    size: Letter;
    margin: 3mm;
  }

  .container {
    background-color: #fff;
    padding: 0;
    display: block;
  }

  .toolbar {
    display: none;
  }

  .page {
    box-shadow: none;
  }

  .h3 {
    font-weight: 400;
  }

  .row {
    min-height: 15.5px;
  }

  .col {
    min-height: 15.5px;
  }

  .rowDoubleLine {
    min-height: 31px;
  }

  .cell {
    font-size: 8px;
    line-height: 11px;
  }

  .cellDoubleLine {
    font-size: 7px;
    line-height: 6px !important;
    height: 15px;
  }

  .cellDoubleLine .row,
  .cellDoubleLine .col {
    min-height: 5px !important;
  }

  .duaSection {
    font-size: 8px;
  }

  .data {
    font-size: 11px;
  }

  .posicionArancelaria {
    font-size: 8px;
  }

  .rectangle {
    height: 7px;
  }

  .cellAduanas .data {
    top: -4px;
  }

  .cellAduanas > .row {
    bottom: -3px;
  }
}
