.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.secction {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
