.container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
}

.containerEmpty {
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.containerTable {
  width: 100%;
  height: calc(100% - 42px);
}

.emptyContainer {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}
