.header {
  display: flex;
  height: 60px !important;
  background-color: #ffffff;
  border-bottom: 1px solid #e6eaed;
  z-index: 1000;

  .menuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #e6eaed;
  }

  .menuContainer svg {
    color: #1890ff;
    font-size: 25px;
  }

  .nav,
  .navExpanded {
    width: calc(100vw - 60px);
    height: 60px !important;
    padding: 0 24px;
  }

  .navExpanded {
    width: calc(100vw - 200px) !important;
  }

  .empresaNombre {
    margin: 0rem !important;
  }
}
