.container {
  width: 100%;
  padding-bottom: 24px;
}

.tabsContainer {
  padding: 0 16px;
  background-color: #ffffff;
  border-radius: 8px !important;
}
