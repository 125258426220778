.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  border-right: 1px solid #e6eaed;
  border-bottom: 1px solid #e6eaed;
  transition: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.logoContainerExpand {
  width: 200px;
}

.imgLogoCollapse {
  float: left;
  width: 35px;
  height: 35px;
}

.imgLogo {
  height: 40px;
}

.logoContainerHome {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: auto;
  height: 60px;
  padding-left: 24px;
}
