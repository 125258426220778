.loadingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.loadingIcon {
  font-size: 50px;
  color: #1890ff !important;
}

.loadingText {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 48px;
}
