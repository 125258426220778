.container {
  display: flex;
  background-color: #ffffff;
  padding: 12px;
  border: 2px solid rgba(0, 0, 0, 0.09);
  border-radius: 8px;
}

.content {
  width: 100%;
}

.tag {
  margin-bottom: 6px !important;
}

.text {
  font-weight: 500;
}

.icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
