.containerMaster {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 400px 400px 600px;
  grid-gap: 24px;
}

.containerMasterNonAdmin {
  display: grid;
  grid-template-areas: 'ordenes';
  grid-template-rows: 100%;
  grid-gap: 0;
  width: calc(100vw - 108px);
  height: calc(100vh - 85px);
  overflow: hidden;
}
