.userImage {
  object-position: center;
  object-fit: contain;
  width: 40px;
  height: 40px;
  clip-path: circle(45%);
}

.dropdown {
  display: flex !important;
  align-items: center;
}

.userName {
  color: #000000;
}

.icon {
  color: #1890ff !important;
  font-size: 11px;
}
