.site-tag-plus {
  background: rgb(161, 214, 245) !important;
  border-style: dashed;
}

.edit-tag-rym {
  user-select: none;
  background: #e9f5ff !important;
  margin-bottom: 6px !important;
}

.edit-tag-rym:hover {
  box-shadow: 1px 2px 2px 1px #2d8ff7;
}

.tag-input {
  width: 78px !important;
  margin-right: 8px !important;
  vertical-align: top;
  margin-bottom: 6px !important;
}