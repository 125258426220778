.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-bottom: 24px;
}

.secction {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
}

.secctionFormOrdenDeTrabajo {
  padding-bottom: 0;
}
