//create table with three columns number,condicion,valor
//header color gray and row and cells border gray

.table {
  border-collapse: collapse;
  width: 100%;

  th {
    border: 1px solid #e2e2e2;
    text-align: left;
    padding: 8px;
    background-color: #f9f9f9;
  }

  tr {
    text-align: left;
    display: grid;
    grid-template-columns: 50px 1fr 300px;
  }
  .rowText {
    text-align: left;
    display: grid;
    grid-template-columns: 50px 1fr ;
  }

  td {
    border: 1px solid #f7f0f0;
    text-align: left;
    padding: 8px;
    display: flex;
    align-items: center;
  }
  #children {
    display: flex;
    align-items: center;
  }

  tr:hover {
    background-color: #f9f9f9;
  }
}
