.sidebarContainer {
  border-right: 1px solid #e6eaed;
  max-height: 100vh !important;
  position: relative;
}

.menuContainer {
  border: none !important;
}

.iconButtonCollapse {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  color: #ffffff;
  font-size: 27px;
}

.sidebarContainerMobile {
  position: absolute !important;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  z-index: 1000;
  box-shadow: 10px 0px 25px -15px;
  border-right: none;
}
