.container {
  background-color: #fafafa;
  padding: 24px;
  border-radius: 8px;
  border: 1px dashed #463c4b;
}

.dragger {
  border: 1px solid #cccccc !important;

  &:hover {
    border: 1px solid #1890ff !important;
  }
}
