.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
}

.readOnlyItem {
  display: flex;
  flex-direction: column;
  padding: 6px;
  background: #f0f0f0;
  border-radius: 4px;

  .label {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  .skeleton {
    height: 20px;
  }
}
