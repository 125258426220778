.container {
  background-color: #ffffff;
  width: 100%;
  height: calc(100% - 24px);
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleText {
  display: inline-block;
}
